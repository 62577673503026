import { XCircleIcon } from '@heroicons/react/24/solid'
import { ReactNode } from 'react'

export const ErrorMessage = ({
  heading,
  message,
  children,
}: {
  heading: string
  message: string
  children?: ReactNode
}) => {
  return (
    <div className="rounded-md p-4 max-w-lg bg-red-50 dark:bg-red-700 mx-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon
            className="h-5 w-5 text-red-400 dark:text-red-200"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800 dark:text-red-200">
            {heading}
          </h3>
          <p className="text-sm mt-2 text-red-700 dark:text-red-200">
            {message}
          </p>
        </div>
      </div>
      <div className="mt-4">{children}</div>
    </div>
  )
}
