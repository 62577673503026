// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/components/react-use-websocket/util.ts"
);
import.meta.hot.lastModified = "1735243046000";
}
// REMIX HMR END

import { WebSocketLike } from './types';
import { resetWebSockets } from './globals';
import { resetSubscribers } from './manage-subscribers';

export function assertIsWebSocket (
    webSocketInstance: WebSocketLike,
    skip?: boolean,
): asserts webSocketInstance is WebSocket {
    if (!skip && webSocketInstance instanceof WebSocket === false) throw new Error('');
};


export function resetGlobalState (url?: string): void {
    resetSubscribers(url);
    resetWebSockets(url);
};
