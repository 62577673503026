import React from 'react'
import { clsx } from 'clsx'

export const Version: React.FC<{ version: string; hide?: boolean, className?: string }> = ({
  version,
  hide,
  className,
}) => {
  if(hide){
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: `<!-- Version: ${version} -->`,
        }}
      ></span>
    )
  }
  return (
    <div
      className={clsx(
        'py-2 text-xs text-center',
        {
          'text-gray-400 dark:text-slate-500':
            !!!className,
        },
        className,
      )}
    >
      Version: {version}
    </div>
  )
}
