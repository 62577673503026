// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/components/react-use-websocket/index.ts"
);
import.meta.hot.lastModified = "1735243046000";
}
// REMIX HMR END

export { useWebSocket as default } from './use-websocket'

export type { SendMessage, Options } from './types'

export { useSocketIO } from './use-socket-io'

export { ReadyState } from './constants'

export { useEventSource } from './use-event-source'

export { resetGlobalState } from './util'
