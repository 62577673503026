import { Fragment, ReactNode } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'

interface SlideoutPanelProps {
  width?: 'slim' | 'wide'
  overlay?: boolean
  isOpen: boolean
  onClose: () => void
  title: string | React.ReactNode
  children: ReactNode
}
export function SlideoutPanel({
  width = 'slim',
  overlay = false,
  isOpen,
  onClose,
  title,
  children,
}: SlideoutPanelProps) {
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[55]" onClose={onClose}>
        {/* <div className="fixed inset-0" /> */}

        <div
          className={clsx('fixed inset-0 overflow-hidden', {
            'pointer-events-none': !overlay,
          })}
        >
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex sm:max-w-full lg:pl-10">
              <TransitionChild
                as={Fragment}
                enter="transform transition ease-in-out duration-100 sm:duration-200"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-100 sm:duration-200"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <DialogPanel
                  className={clsx('pointer-events-auto w-screen', {
                    'max-w-72': width === 'slim',
                    'w-full sm:max-w-xl': width === 'wide',
                  })}
                >
                  <div className="flex h-full flex-col overflow-y-scroll py-6 shadow-xl bg-white dark:bg-slate-700 dark:shadow-slate-800">
                    <div className="px-4 sm:px-6">
                      <div className="relative flex items-start justify-between">
                        <DialogTitle className="text-2xl font-medium leading-6 text-gray-900 dark:text-slate-400">
                          {title}
                        </DialogTitle>
                        <div className="absolute -top-2 right-0">
                          <button
                            type="button"
                            className={clsx(
                              'relative rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2',
                              ' bg-white dark:bg-slate-700 text-gray-400 hover:text-gray-500 focus:ring-indigo-500',
                            )}
                            onClick={onClose}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <hr className="m-6 border-b border-b-neutral-400 dark:border-b-slate-500" />
                    <div className="relative flex-1 px-4 sm:px-6">
                      {children}
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
