import { useCallback, useEffect, useState } from 'react'
import { showAlert } from '~common/components/alert'
import { useAlert } from '~common/components/alert/hooks'

const useNetworkStatus = () => {
  const [isOnline, setOnline] = useState<boolean>(true)
  const { showError, showSuccess } = useAlert()
  const setOnlineToTrue = useCallback(() => {
    showSuccess({
      message: 'You are back online!',
    })

    setOnline(true)
  }, [])
  const setOnlineToFalse = useCallback(() => {
    showError({
      message: 'You seem to be without an Internet connection',
    })

    setOnline(false)
  }, [])

  useEffect(() => {
    window.addEventListener('online', setOnlineToTrue)
    window.addEventListener('offline', setOnlineToFalse)
    return () => {
      window.removeEventListener('online', setOnlineToTrue)
      window.removeEventListener('offline', setOnlineToFalse)
    }
  }, [setOnlineToTrue, setOnlineToFalse])

  return isOnline
}
export default useNetworkStatus
