// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/modules/account-settings/components/LoginDropdown.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/modules/account-settings/components/LoginDropdown.tsx");
  import.meta.hot.lastModified = "1735243046000";
}
// REMIX HMR END

import React from 'react';
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { offset, shift, useFloating } from '@floating-ui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { IdentificationIcon } from '@heroicons/react/24/outline';
import { getAvatarLabel } from '~common/utils/format/avatarLabelFormat';
import { Avatar, Button } from '~ui';
import clsx from 'clsx';
const resources = [{
  name: 'Account',
  description: 'Manage your basic information',
  href: '/account-settings',
  icon: IdentificationIcon
}
// {
//   name: 'Guides',
//   description: 'Learn how to maximize our platform',
//   href: '#',
//   icon: BookmarkSquareIcon,
// },
// {
//   name: 'Events',
//   description: 'See meet-ups and other events near you',
//   href: '#',
//   icon: CalendarDaysIcon,
// },
];
export const LoginDropdown = ({
  administrator,
  className = 'text-gray-400 hover:text-gray-600',
  onSignOut
}) => {
  _s();
  const {
    refs,
    floatingStyles
  } = useFloating({
    placement: 'bottom-end',
    transform: false,
    middleware: [offset({
      mainAxis: 10,
      crossAxis: 0,
      alignmentAxis: 0
    }), shift()]
  });
  return <Popover>
      <PopoverButton
    // @ts-ignore hmm-kay
    ref={refs.setReference} className={clsx('focus:outline-none -m-1.5 flex items-center p-1.5 inline-flex items-center gap-x-1 text-sm font-semibold leading-6', 'text-gray-900 dark:text-slate-400')}>
        <span className="sr-only">Open user menu</span>
        <Avatar altContent={getAvatarLabel(administrator.firstName, administrator.lastName)} size="xs" source={administrator.customFields?.avatar?.preview} className="h-8 w-8 rounded-full bg-gray-50" />
        <span className="hidden lg:flex lg:items-center">
          <span className="ml-4 text-sm font-semibold leading-6 text-gray-900 dark:text-slate-400" aria-hidden="true">
            {administrator.firstName} {administrator.lastName}
          </span>
          <ChevronDownIcon className={clsx('ml-2 h-5 w-5', className)} aria-hidden="true" />
        </span>
      </PopoverButton>

      <Transition as={React.Fragment} enter="transition ease-out duration-200" enterFrom="opacity-0 translate-y-1" enterTo="opacity-100 translate-y-0" leave="transition ease-in duration-150" leaveFrom="opacity-100 translate-y-0" leaveTo="opacity-0 translate-y-1">
        <PopoverPanel
      // @ts-ignore hmm-kay
      ref={refs.setFloating}
      // className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4"
      style={floatingStyles}>
          <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl text-sm leading-6 shadow-lg ring-1 bg-white dark:bg-slate-900 ring-gray-900/5 dark:ring-slate-500">
            <div className="p-4">
              {resources.map(item => <div key={item.name} className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50 dark:hover:bg-slate-600">
                  <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 dark:bg-slate-700 group-hover:bg-white dark:group-hover:bg-slate-700">
                    <item.icon className="h-6 w-6 text-gray-600 dark:text-slate-400 group-hover:text-primary-600 dark:group-hover:text-slate-500" aria-hidden="true" />
                  </div>
                  <div>
                    <a href={item.href} className="font-semibold text-gray-900 dark:text-slate-300">
                      {item.name}
                      <span className="absolute inset-0" />
                    </a>
                    <p className="mt-1 text-gray-600 dark:text-slate-400">
                      {item.description}
                    </p>
                  </div>
                </div>)}
            </div>

            <Button onClick={onSignOut} className={clsx('w-full text-center block py-6 px-3 py-1 text-sm font-semibold leading-6 bg-gray-50 text-gray-900 dark:bg-slate-900 dark:text-slate-400 dark:hover:bg-slate-700', 'g_id_signout')} variant="unstyled">
              Logout
            </Button>
          </div>
        </PopoverPanel>
      </Transition>
    </Popover>;
};
_s(LoginDropdown, "yf5c1DUaoqCxhOjCmzgGhV8T+Fs=", false, function () {
  return [useFloating];
});
_c = LoginDropdown;
var _c;
$RefreshReg$(_c, "LoginDropdown");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;