export const getCookie = (name: string): string | undefined => {
  if (typeof document === 'undefined') {
    return undefined
  }
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) {
    const ck = parts.pop()?.split(';').shift() ?? ''
    return atob(ck)
  }
  return undefined
}

export const setCookie = (name: string, value: string, days = 30): void => {
  const date = new Date()
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
  const expires = `expires=${date.toUTCString()}`
  document.cookie = `${name}=${value};${expires};path=/`
}

export const deleteCookie = (name: string): void => {
  setCookie(name, '', -1) // Set the cookie with a past expiration date
}
