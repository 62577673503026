// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/components/NetworkObserver.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/components/NetworkObserver.tsx");
  import.meta.hot.lastModified = "1736251956000";
}
// REMIX HMR END

import useNetworkStatus from '~common/hooks/useNetworkStatus';
import { SignalSlashIcon } from '@heroicons/react/24/outline';
export const NetworkObserver = () => {
  _s();
  const isOnline = useNetworkStatus();
  return <>
      {!isOnline && <div className="fixed inset-0 bg-black/60 dark:bg-slate-700/50  flex items-center justify-center z-50 ">
          <div className="bg-white/20  dark:bg-slate-700 text-white dark:text-slate-400 text-center p-6 rounded-lg shadow-lg flex flex-col items-center justify-center">
            <SignalSlashIcon className="h-6 w-6 dark:text-slate-300" aria-hidden="true" />
            <h1 className="dark:text-slate-300 text-xl">
              Oops! Looks like You are Offline.
            </h1>
          </div>
        </div>}
    </>;
};
_s(NetworkObserver, "UMa5uL/uM9l3PDW7O/yHtKl8KTE=", false, function () {
  return [useNetworkStatus];
});
_c = NetworkObserver;
var _c;
$RefreshReg$(_c, "NetworkObserver");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;