import clsx from 'clsx'

import { LoadingIndicator } from '../LoadingIndicator'

import { ButtonProps, ButtonSize, ButtonVariant } from './button'
export type { ButtonProps }

const getVariant = (
  variant: ButtonVariant,
  enabled: boolean,
  border: boolean,
) => {
  let clazz = ''
  switch (variant) {
    case 'primary':
      clazz = clsx(
        'bg-primary-600 text-white hover:text-white hover:bg-primary-700 focus:bg-primary-700 focus:ring-primary-300 disabled:text-primary-200 disabled:bg-primary-400',
        'dark:disabled:bg-primary-600 dark:disabled:text-primary-400',
      )
      break
    case 'secondary':
      clazz = clsx(
        'bg-white text-gray-700 hover:bg-gray-50 focus:bg-gray-100 focus:ring-gray-300 disabled:text-gray-400 disabled:bg-gray-50',
        'dark:bg-slate-800 dark:text-slate-400 dark:border-slate-500 dark:focus:bg-slate-700 dark:focus:ring-slate-500 dark:hover:bg-slate-700 dark:hover:text-slate-300 dark:disabled:text-slate-600',
      )
      break
    case 'warn':
      clazz = clsx(
        'bg-warn-600 text-white hover:text-white hover:bg-warn-700 focus:bg-warn-700 focus:ring-warn-300 disabled:bg-warn-300',
        'dark:disabled:bg-warn-600 dark:disabled:text-warn-400',
      )
      break
    case 'error':
      clazz = clsx(
        'bg-error-600 text-white hover:text-white hover:bg-error-700 focus:bg-error-700 focus:ring-error-300 disabled:bg-error-300',
        'dark:disabled:bg-error-600 dark:disabled:text-error-400',
      )
      break
    case 'muted':
      clazz =
        'bg-gray-400 text-gray-50 hover:bg-gray-500 focus:bg-gray-500 focus:ring-gray-500 text-white disabled:text-gray-400 disabled:bg-gray-50'
      break
    case 'dark':
      clazz =
        'bg-gray-600 text-gray-50 hover:bg-gray-8800 focus:bg-gray-700 focus:ring-gray-700 disabled:text-gray-700 disabled:bg-gray-50'
      break

    case 'unstyled':
      return ''
  }
  return clsx(
    'focus:outline-none focus:z-10 focus:ring-1 focus:ring-offset-1 font-normal flex items-center justify-around gap-2 disabled:cursor-not-allowed',
    {
      'ring-white-600 dark:ring-slate-300': border,
    },
    clazz,
  )
}

const getSize = (size: ButtonSize) => {
  switch (size) {
    case 'sm':
      return 'px-5 py-1'
    case 'md':
      return 'px-6 py-1'
    case 'lg':
      return 'px-12 py-2'
    case 'xl':
      return 'px-14 py-3'
    case '2xl':
      return 'px-16 py-3'
  }
}

export const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  variant = 'primary',
  size = 'sm',
  type = 'button',
  rounded = 'rounded-full',
  isInButtonGroup,
  isSubmitting = false,
  border = true,
  textAlign,
  iconStart,
  iconEnd,
  disabled,
  ...props
}) => {
  const btnGroupClazz = clsx(
    'relative py-2 text-sm font-semibold ring-1 ring-inset focus:z-10',
    'bg-white text-gray-900 ring-gray-300 disabled:bg-gray-200 disabled:text-gray-400 hover:bg-gray-50 dark:bg-slate-900 dark:text-slate-400 dark:ring-slate-500 disabled:dark:bg-slate-800 disabled:dark:text-slate-600 hover:dark:bg-slate-700',
  )
  return (
    <button
      {...props}
      disabled={disabled || isSubmitting}
      type={type}
      className={clsx(
        'inline-flex items-center justify-center flex-nowrap cursor-pointer shrink-0',
        {
          [btnGroupClazz]: !!isInButtonGroup,
          [getVariant(variant, !disabled, border)]: !isInButtonGroup,
        },
        variant !== 'unstyled' && rounded,
        variant !== 'unstyled' && getSize(size),
        props.className,
      )}
    >
      {iconStart ? <span className="mr-1">{iconStart}</span> : null}
      <span
        className={clsx('inline-flex items-center gap-2', {
          'grow text-left': textAlign === 'left',
          'grow text-right': textAlign === 'right',
        })}
      >
        {props.children}
      </span>
      {isSubmitting ? (
        <span className="ml-1">
          <LoadingIndicator className="w-4 h-4 ml-2" />
        </span>
      ) : iconEnd ? (
        <span className="ml-1">{iconEnd}</span>
      ) : null}
    </button>
  )
}
