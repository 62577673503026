import { toZonedTime } from 'date-fns-tz'

import { getCookie } from './cookie'

export const TIMEZONE_COOKIE = 'vendure_remix_timezone'

export const toStoreTimeZone = (
  date: Date | string | number,
  cookieName = TIMEZONE_COOKIE,
) => {
  let timezone = 'America/Chicago'
  const ck = getCookie(cookieName)
  if (ck) {
    try {
      const data = JSON.parse(timezone)
      if (data.timezone) {
        timezone = data.timezone
      }
    } catch (error) {
      // do nothing
    }
  }
  return toZonedTime(date, timezone)
}

export const getStoreTimeZone = (cookieName = TIMEZONE_COOKIE) => {
  let timezone = 'America/Chicago'
  const ck = getCookie(cookieName)
  if (ck) {
    try {
      const data = JSON.parse(timezone)
      if (data.timezone) {
        timezone = data.timezone
      }
    } catch (error) {
      // do nothing
    }
  }
  return timezone
}

export const todayStoreTimeZone = (cookieName = TIMEZONE_COOKIE) => {
  let timezone = 'America/Chicago'
  const ck = getCookie(cookieName)
  if (ck) {
    try {
      const data = JSON.parse(timezone)
      if (data.timezone) {
        timezone = data.timezone
      }
    } catch (error) {
      // do nothing
    }
  }
  return toZonedTime(new Date(), timezone)
}
