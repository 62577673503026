import React from 'react'
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
  useGoogleReCaptcha,
  GoogleReCaptchaContext,
} from 'react-google-recaptcha-v3'
import { z } from 'zod'

export { GoogleReCaptcha as ReCaptcha, useGoogleReCaptcha as useReCaptcha }

export const ReCaptchaProvider: React.FC<
  React.PropsWithChildren<{ publicKey: string | undefined | null }>
> = ({ publicKey, children }) => {
  React.useEffect(() => {
    console.log(
      `Recaptcha ${publicKey ? 'active' : 'NOT active (missing key)'}`,
    )
    // this creates problems
    // return () => {
    //   // @ts-ignore ok
    //   window.grecaptcha = undefined
    // }
  }, [])

  if (!publicKey) {
    return children
  }
  return (
    <GoogleReCaptchaProvider
      // @ts-ignore let's process missing key elegantly
      reCaptchaKey={publicKey}
      scriptProps={{
        defer: true,
      }}
    >
      {children}
    </GoogleReCaptchaProvider>
  )
}

export const RecaptchaFormField: React.FC<{ name?: string }> = ({
  name = '_captcha',
}) => {
  const captchaContext = React.useContext(GoogleReCaptchaContext)
  const [refreshCaptcha, setRefreshCaptcha] = React.useState<
    number | undefined
  >()
  const [exists, setExists] = React.useState<boolean>(false)
  const [token, setToken] = React.useState<string>('')
  const handleVerify = React.useCallback((token: string) => {
    setToken(token ?? '')
  }, [])
  React.useEffect(() => {
    let interval: NodeJS.Timeout
    if (exists) {
      return
    }
    try {
      if (captchaContext?.executeRecaptcha) {
        captchaContext.executeRecaptcha()
        setExists(true)
        interval = setInterval(() => {
          setRefreshCaptcha(Math.random() * 1000)
        }, 90000)
      }
    } catch (err) {
      // throws an error if not loaded
      setExists(false)
    }
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [captchaContext])
  return exists ? (
    <>
      <GoogleReCaptcha
        onVerify={handleVerify}
        refreshReCaptcha={refreshCaptcha}
      />
      <input type="hidden" name={name} value={token} />
    </>
  ) : null
}

export const captchaFormFieldValidator = {
  _captcha: z.string().optional().nullable(),
}
